/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-extraneous-dependencies */
import { Card, Container, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs'
import { useParams } from 'src/routes/hook'
import {
  handleDeleteSection,
  handleGeneratePdf,
  useFetchBudgetById,
} from 'src/service/budgets.service'

import { paths } from 'src/routes/paths'
import { useSettingsContext } from 'src/components/settings'
import Datagrid from 'src/components/datagrid'
import Button from '@mui/material/Button'
import FormProvider from 'src/components/hook-form/form-provider'
import { useForm } from 'react-hook-form'
import axiosInstance from 'src/utils/axios'
import { enqueueSnackbar } from 'src/components/snackbar'
import { Loading } from 'notiflix/build/notiflix-loading-aio'
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt'
// eslint-disable-next-line import/no-duplicates
import { addDays, format } from 'date-fns'
// eslint-disable-next-line import/no-duplicates
import { ptBR } from 'date-fns/locale'
import RHFEditor from 'src/components/hook-form/rhf-editor'

import { useBoolean } from 'src/hooks/use-boolean'
import Iconify from 'src/components/iconify'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import BudgetPreviewTableRow from './table-budget-preview/budget-table-row'
import AddPackage from './add-package'
import AddSection from './add-section'
import EditBudget from './edit-budget'
import EditSection from './edit-section'
import { TABLE_HEAD, tableHead } from './constants/tableHead'
import { updateOrder } from './services/updateOrder'

const defaultPagination = {
  page: 1,
  perPage: 50,
  sort: {
    createdAt: 'desc',
  },
}

function concatenateTableHeaders(
  tableHead2: TableColumn[],
  additionalHeaders: TableHead,
  conditions: any,
) {
  const finalTableHead = [...tableHead2]

  Object.entries(conditions).forEach(([key, value]) => {
    if (value) {
      // @ts-ignore
      finalTableHead.push(additionalHeaders[key])
    }
  })

  finalTableHead.push({ id: 'delete', label: '', width: 88 })

  return finalTableHead
}

export const BudgetPreview: React.FC<any> = () => {
  const settings = useSettingsContext()
  const [pagination, setPagination] = useState(defaultPagination)
  const params = useParams()

  const [addPackage, setAddPackage] = useState<{ name: string; _id: string }>()
  const [editName, setEditname] = useState<{ name: string }>()
  const { id } = params
  const quickEdit = useBoolean()

  const addSection = useBoolean()
  const editSection = useBoolean()
  const editBudget = useBoolean()

  const { data, refetch, isLoading } = useFetchBudgetById(id!)

  const onSubmit = useCallback(
    async (data2: any) => {
      try {
        await axiosInstance.put(`/budgets/${data._id}`, {
          ...data2,
        })

        enqueueSnackbar('Observação alterada com sucesso!')
      } catch (error) {
        if (error?.message) {
          enqueueSnackbar(error?.message, {
            variant: 'error',
          })
        } else {
          enqueueSnackbar('Erro na requisição!', {
            variant: 'error',
          })
        }
      }
    },
    [data?._id],
  )
  const [packages, updatePackages] = useState([])

  useEffect(() => {
    console.log('ta vindo aqui')
    updatePackages(data?.packages)
  }, [data?.packages])

  const methods = useForm<any>({
    /*     resolver: yupResolver(EditClientPackageSchema), */
  })

  function handleOnDragEnd(result: any) {
    if (!result.destination) return
    const items = Array.from(packages)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    updateOrder(id!, { packages: items })
    updatePackages(items)
  }

  if (isLoading) {
    return <>{Loading.arrows('Carregando...')}</>
  }

  methods.setValue(
    'observation',
    data?.observation ??
      `<p>
    <strong>
    
    - Este orçamento é válido até ${format(new Date(addDays(new Date(), 7)), 'dd/MM/yyyy', {
      locale: ptBR,
    })}
    </p>
    <p>-----------------------------------------------------------------------------</p>
    <p>DOCUMENTOS NECESSÁRIOS PARA ENTRADA NA ARGENTINA:</p>
    <p>- CNH dentro da validade </p>
    <p>- RG com no máximo 10 anos de expedição </p>
<p>- PASSAPORTE dentro da validade </p>
<p>- NENHUM documento DIGITAL será aceito! </p>
<p>- No caso de ter crianças junto,os dois pais biológicos precisam estar juntos
para entrar na Argentina, caso algum dos dois não esteja presente, é
necessário apresentar uma autorização judicial autorizando a viajar sem a
presenta do(a) pai/mãe.
(Em caso de falecimento de um dos pais biológicos, é necessário apresentar atestado de óbito.)
 </p>
 <p>-----------------------------------------------------------------------------</p>
 <p>ATENÇÃO: Os horários aqui passados, são BASE. Pode variar entre 5 e 20 minutos para mais ou para menos.</p>

<p>Passeio DIURNOS avisamos o horário exato até as 20h no dia anterior ao passeio. Para os passeios NOTURNOS, avisamos no mesmo dia até as 14h.</p>
<p>-----------------------------------------------------------------------------</p>
<p>
https://www.instagram.com/p/Cq29bFjug1V/?img_index=1
</strong>
</p>`,
  )
  Loading.remove()

  const { handleSubmit } = methods

  return (
    <>
      <Helmet>
        <title>Orçamentos - Visualização</title>
      </Helmet>

      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading={`Visualização de orçamento - ${data?.name}`}
          links={[
            { name: 'Orçamentos', href: paths.dashboard.budgets.root },
            { name: 'Visualizar orçamento' },
          ]}
          sx={{ mb: { xs: 3, md: 5 } }}
          action={
            <Grid spacing={2} container>
              <Grid item>
                <Button
                  onClick={addSection.onTrue}
                  variant='contained'
                  startIcon={<Iconify icon='mingcute:add-line' />}
                >
                  Adicionar uma sessão
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={editBudget.onTrue}
                  variant='contained'
                  startIcon={<Iconify icon='solar:pen-bold' />}
                >
                  Editar
                </Button>
              </Grid>
            </Grid>
          }
        />

        <Card sx={{ padding: 4 }}>
          <div>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId='packages'>
                {(provided) => (
                  <div className='packages' {...provided.droppableProps} ref={provided.innerRef}>
                    {packages?.map((item: any, index: number) => (
                      <Draggable key={item.name} draggableId={item.name} index={index}>
                        {(provided2) => (
                          <div
                            ref={provided2.innerRef}
                            {...provided2.draggableProps}
                            {...provided2.dragHandleProps}
                          >
                            <Grid
                              container
                              direction='row'
                              spacing={0}
                              sx={{
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <Grid item xs={1}>
                                <Tooltip title='Reorganizar'>
                                  <Iconify icon='gg:arrows-shrink-v' width={25} />
                                </Tooltip>
                              </Grid>

                              <Grid item xs={5}>
                                <Typography variant='h3'>
                                  {format(new Date(item.name), 'dd/MM/yyyy - EEE', {
                                    locale: ptBR,
                                  })}
                                </Typography>
                              </Grid>
                              <Grid item xs={6} textAlign='right'>
                                <Tooltip
                                  title={`Adicionar pacote - ${format(
                                    new Date(item.name),
                                    'dd/MM/yyyy - EEE',
                                    {
                                      locale: ptBR,
                                    },
                                  )}`}
                                >
                                  <IconButton
                                    onClick={() => {
                                      setAddPackage({ name: item.name, _id: data._id })
                                      quickEdit.onTrue()
                                    }}
                                  >
                                    <AddLocationAltIcon color='primary' cursor='pointer' />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip
                                  title={`Editar - ${format(
                                    new Date(item.name),
                                    'dd/MM/yyyy - EEE',
                                    {
                                      locale: ptBR,
                                    },
                                  )}`}
                                  placement='top'
                                  arrow
                                >
                                  <IconButton
                                    onClick={() => {
                                      setEditname({ name: item.name })
                                      editSection.onTrue()
                                    }}
                                  >
                                    <Iconify icon='solar:pen-bold' />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip
                                  title={`Deletar - ${format(
                                    new Date(item.name),
                                    'dd/MM/yyyy - EEE',
                                    {
                                      locale: ptBR,
                                    },
                                  )}`}
                                >
                                  <IconButton
                                    onClick={() => {
                                      console.log(item.name, id)
                                      handleDeleteSection(id!, item.name, refetch)
                                    }}
                                  >
                                    <Iconify color='red' icon='solar:trash-bin-trash-bold' />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </Grid>
                            <Datagrid
                              data={item.package || []}
                              headLabel={concatenateTableHeaders(
                                TABLE_HEAD,
                                tableHead,
                                data.table_head,
                              )}
                              total={item.package.length}
                              setPagination={setPagination}
                              pagination={pagination}
                              handleDeleteRows={() => console.log('deelte')}
                              ItemContent={(e) =>
                                BudgetPreviewTableRow({
                                  ...e,
                                  tableHead: concatenateTableHeaders(
                                    TABLE_HEAD,
                                    tableHead,
                                    data.table_head,
                                  ),
                                  budgetId: id,
                                  packageName: item.name,
                                  refetch,
                                })
                              }
                              disableFooter
                              noSelection
                              notFound={false}
                              refetch={() => {}} // refetch
                              handleDeleteRow={() => console.log('delete')}
                              handleEditRow={() => console.log('edit')}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <Grid
              container
              direction='row'
              sx={{
                justifyContent: 'end',
                alignItems: 'end',
                mt: 2,
              }}
              spacing={2}
            >
              <Grid item xs={12}>
                <FormProvider methods={methods}>
                  <Typography variant='h6'>Observação:</Typography>
                  <RHFEditor name='observation' onBlur={handleSubmit(onSubmit)} />
                </FormProvider>
              </Grid>
              <Grid item xs={2} alignSelf='flex-end'>
                <Button
                  variant='contained'
                  type='button'
                  onClick={() => handleGeneratePdf(id!, data.name)}
                >
                  Finalizar/Gerar PDF
                </Button>
              </Grid>
            </Grid>
          </div>
        </Card>
      </Container>

      <AddPackage
        onRefleshTable={() => refetch()}
        open={quickEdit.value}
        currentUser={addPackage}
        onClose={quickEdit.onFalse}
      />

      <AddSection
        open={addSection.value}
        onClose={addSection.onFalse}
        onRefleshTable={() => refetch()}
        currentUser={{ _id: id! }}
      />

      <EditSection
        open={editSection.value}
        onClose={editSection.onFalse}
        onRefleshTable={() => refetch()}
        currentUser={{ _id: id!, name: editName?.name }}
      />

      <EditBudget
        open={editBudget.value}
        onClose={editBudget.onFalse}
        onRefleshTable={() => refetch()}
        currentUser={data}
      />
    </>
  )
}
